<template>
  <div class="error-msg" >
    <h1>{{$errorMsg}}</h1>
    <div class="error-decoration">
      <svg-icon name="error" height="100%" width="auto"></svg-icon>
    </div>
    <el-button v-if="showBtn" data-tag-id="contract-edit-back-to-index" @click="$btnHandler">{{$btnMsg}}</el-button>
  </div>
</template>

<script>

import SvgIcon from "../components/SvgIcon/svgIcon";
import {colorLog} from "../util";
export default {
  name: "Error",
  props:{
    'errorMsg':{
      required:false
    },
    'btnMsg':{
      required:false
    },
    'btnHandler':{
      required:false
    },
    'showBtn':{
      required:false,
      default:true
    },
  },
  components: {SvgIcon},
  data(){
    colorLog.red('router',this.$router,this.$route)
    const getExternalValue = (function (propName,defaultvalue){
      if(this.$route.query[propName]){
        return this.$route.query[propName];
      }else if(this.$props[propName]){
        return this.$props[propName];
      }else if(this.$route.meta[propName]){
        return this.$route.meta[propName];
      }else{
        return defaultvalue;
      }
    }).bind(this);

    let $errorMsg = getExternalValue('errorMsg',"服务器错误,请稍后再试");
    let $btnMsg = getExternalValue('btnMsg',"返回首页");
    let $btnHandler = getExternalValue('btnHandler',this.returnIndex);

    return {
      $errorMsg,
      $btnMsg,
      $btnHandler
    }
  },
  methods:{
    returnIndex(){
      this.$router.push({name:'contractIndex'});
    },
  }
}
</script>

<style scoped>
.error-msg{
  overflow: hidden;
}
.error-msg h1{
  margin-bottom: 0;
}
.error-decoration{
  margin-top:-38px;

  height: 400px;
}
</style>
