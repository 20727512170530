<template>
  <div class="noApproval-wrap" v-if="rejectType == 'noApproval'">
    <Error
        :error-msg="'操作无权限'"
        :showBtn="false"
    ></Error>
  </div>
  <div v-else class="aready-register-wrap">
    <div class="aready-login-wrap">
      <img src="../../assets/images/productBanner.png" class="logo-img">
      <p>您已是秘塔科技用户</p>
      <p style="font-size: 16px;">请用
        <span style="font-weight: bold; font-size: 18px; color: #ff6060;">【电脑浏览器】</span>打开
      </p>
      <p style="font-size: 16px; color: #3759CB;" >{{ baseUrl }}clm/#/home</p>
    </div>
  </div>
</template>

<script>
import config from "../../config";
import MetaGoLogo from "../../components/universalUI/MetaGoLogo";
import Error from "../Error";
export default {
  name: "ResolveReject",
  components: {Error, MetaGoLogo},
  data(){
    return {
      rejectType:this.$route.query.type,
      baseUrl:config.baseUrl
    };
  }
}
</script>

<style scoped lang="scss">
.noApproval-wrap{
  .noApproval-tip{
    text-align: center;
  }
}
.aready-register-wrap {
  padding-bottom: 3rem;
  .aready-login-wrap {
    padding-top: 2rem;
}
  .logo-img {
    width: 80vw;
    height: auto;
    margin: 2rem auto;
}
  .wechat-img {
    width: 160px;
    height: 160px;
    margin-top: 15px;
}
  p {
    line-height: 24px;
}
  .mbvideo-wrap {
    margin-top: 20px;
    padding: 0 10px;
    video {
      border: 1px solid #ddd;
      border-radius: 4px;
      overflow: hidden;
  }
}
}

</style>
